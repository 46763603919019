<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loadingState">
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                          <b-col lg="12" sm="12">
                            <div>
                              <b-table-simple striped bordered small>
                                <b-tr>
                                  <b-th>{{ $t('dae_grant_allocation_distribution.fiscal_year') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.fiscal_year_bn : demmandDetailsData.fiscal_year }}</b-td>
                                  <b-th>{{ $t('dae_grant_allocation_distribution.season') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.season_bn : demmandDetailsData.seasson }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th>{{ $t('dae_config.circular_type') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.circular_type_bn : demmandDetailsData.circular_type }}</b-td>
                                  <b-th>{{ $t('dae_grant_allocation_distribution.circular') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.circular_bn : demmandDetailsData.circular }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th>{{ $t('dae_grant_allocation_distribution.project') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn')? demmandDetailsData.project_bn : demmandDetailsData.project }}</b-td>
                                  <b-th>{{ $t('globalTrans.division') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn') ? demmandDetailsData.division_name_bn : demmandDetailsData.division_name }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-th>{{ $t('globalTrans.district') }}</b-th>
                                  <b-td>{{ ($i18n.locale=='bn') ? demmandDetailsData.district_name_bn : demmandDetailsData.district_name }}</b-td>
                                  <b-th>{{ $t('globalTrans.upazila') }}</b-th>
                                  <b-td colspan="3">{{ ($i18n.locale=='bn') ? demmandDetailsData.upazilla_name_bn : demmandDetailsData.upazilla_name }}</b-td>
                                </b-tr>
                              </b-table-simple>
                              <b-row>
                                <b-col lg="12" sm="12">
                                     <template>
                                        <div class="col-sm-12 col-md-12" style="font-size:14px; background-color: #337982; padding:6px">
                                            <h5 class="text-white text-center">
                                                {{ $t('dae_grant_allocation_distribution.demand_summary') }}
                                            </h5>
                                        </div>
                                    </template>
                                   <div v-for="(detail, dlIndx) in demand_details" :key="'A'+dlIndx" class="mt-1" style="padding:6px">
                                    <h5>{{ $t('globalTrans.union') }}: {{ $i18n.locale === 'bn' ? detail.union_name_bn : detail.union_name }} </h5>
                                     <div class="table-responsive">
                                        <b-table-simple striped bordered small class="mt-2">
                                          <b-thead>
                                          <b-tr>
                                            <b-th rowspan="3" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th rowspan="3" class="text-center">{{ $t('dae_subsidy.farmer_name') }}</b-th>
                                            <b-th rowspan="3" class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_father_name') }}</b-th>
                                            <b-th rowspan="3" class="text-center">{{ $t('common_config.nid_no') }}</b-th>
                                            <b-th rowspan="3" class="text-center">{{ $t('common_config.mobile_no') }}</b-th>
                                            <b-th :colspan="parseInt(policyCrops.length ? policyCrops.length : 1) + parseInt(policyMaterials.length ? policyMaterials.length : 1) + 1" class="text-center">{{ $t('dae_grant_allocation_distribution.allocation_info') }}</b-th>
                                          </b-tr>
                                          <b-tr>
                                            <b-th class="text-center" rowspan="2">{{ $t('dae_grant_allocation_distribution.cash_tk') }}</b-th>
                                            <b-th :colspan="policyCrops.length ? policyCrops.length : 1" class="text-center">{{ $t('dae_grant_allocation_distribution.crop_name') }}</b-th>
                                            <b-th :colspan="policyMaterials.length ? policyMaterials.length : 1" class="text-center">{{ $t('dae_grant_allocation_distribution.fertilizer') }}</b-th>
                                          </b-tr>
                                          <b-tr>
                                            <b-th v-for="(pCrop, pCropIndex) in policyCrops" :key="'B'+pCropIndex" class="text-center">
                                              {{ ($i18n.locale === 'bn') ? pCrop.crop_name_bn : pCrop.crop_name }}
                                            </b-th>
                                            <b-th v-for="(pMatr,pMatrIndex) in policyMaterials" :key="'c'+pMatrIndex" class="text-center">
                                              {{ ($i18n.locale === 'bn') ? pMatr.material_name_bn : pMatr.material_name }}
                                            </b-th>
                                          </b-tr>
                                          </b-thead>
                                          <b-tbody>
                                          <b-tr v-for="(detailItem, dtlIndex) in detail.farmer_info" :key="'D'+dtlIndex">
                                              <b-td class="text-left">{{ $n(dtlIndex+1) }}</b-td>
                                              <b-td class="text-left">{{ $i18n.locale === 'bn' ? detailItem.farmer_data.name_bn : detailItem.farmer_data.name }}</b-td>
                                              <b-td class="text-left">{{ $i18n.locale === 'bn' ? detailItem.father_name_bn : detailItem.father_name }}</b-td>
                                              <b-td class="text-left">{{ $n(detailItem.nid_no, { useGrouping: false }) }}</b-td>
                                              <b-td class="text-left"> {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(detailItem.mobile_no, { useGrouping: false } ) }}</b-td>
                                            <b-td class="text-right">{{ typeof detailItem.circular.distribution_policy !== 'undefined' ? $n(detailItem.circular.distribution_policy.amount_per_farmer, { useGrouping: false }) : $n(0) }}</b-td>
                                                <b-td v-for="(sinPCrop, sinPCropIndex) in policyCrops" :key="'E'+sinPCropIndex" class="text-center">
                                                  {{ getCropNameData(sinPCrop,detailItem.demand_crop_approval)}} {{ getCropNameData(sinPCrop,detailItem.demand_crop_approval) !== '-' ? getCropUnitType(sinPCrop.unit_type) : '' }}
                                                </b-td>
                                                <b-td v-for="(sinPMaterial,sinPMaterialIndex) in policyMaterials" :key="'F'+sinPMaterialIndex" class="text-center">
                                                  {{ getMeterialNameData(sinPMaterial,detailItem.demand_material_approval)}} {{ getMeterialNameData(sinPMaterial,detailItem.demand_material_approval) !== '-' ? getMaterialUnitType(sinPMaterial.unit_type) : '' }}
                                                </b-td>
                                          </b-tr>
                                          </b-tbody>
                                          <b-tfoot>
                                            <b-tr>
                                              <b-th colspan="5">{{ $t('globalTrans.total') }}</b-th>
                                              <b-th class="text-right">{{ $n(detail.union_total.cash_tk, { useGrouping: false }) }}</b-th>
                                              <b-th v-for="(sinPCrop,sinPCropIndex) in policyCrops" :key="'c-'+ sinPCropIndex+300" class="text-center">
                                                {{ detail.union_total.crops[sinPCrop.crop_id] ? ($n(detail.union_total.crops[sinPCrop.crop_id], { useGrouping: false }) + ' ' + getCropUnitType(sinPCrop.unit_type)) : '-' }}
                                              </b-th>
                                              <b-th v-for="(sinPMaterial,sinPMaterialIndex) in policyMaterials" :key="'m-'+sinPMaterialIndex+300" class="text-center">
                                                {{ detail.union_total.materials[sinPMaterial.material_id] ? ($n(detail.union_total.materials[sinPMaterial.material_id], { useGrouping: false }) + ' ' + getMaterialUnitType(sinPMaterial.unit_type)) : '-' }}
                                              </b-th>
                                            </b-tr>
                                            <template v-if="(dlIndx + 1) === demand_details.length">
                                              <b-tr>
                                                <b-th :colspan="5 + (parseInt(policyCrops.length ? policyCrops.length : 1) + parseInt(policyMaterials.length ? policyMaterials.length : 1) + 1)">&nbsp;
                                                </b-th>
                                              </b-tr>
                                              <b-tr>
                                                <b-th colspan="5">{{ $t('sitePreference.grandTotal') }}</b-th>
                                                <b-th class="text-right">{{ $n(totalRowItem.cash_tk, { useGrouping: false }) }}</b-th>
                                                <b-th v-for="(sinPCrop,sinPCropIndex) in policyCrops" :key="'ct-'+ sinPCropIndex+300" class="text-center">
                                                  {{ totalRowItem.crops[sinPCrop.crop_id] ? ($n(totalRowItem.crops[sinPCrop.crop_id], { useGrouping: false }) + ' ' + getCropUnitType(sinPCrop.unit_type)) : '-' }}
                                                </b-th>
                                                <b-th v-for="(sinPMaterial,sinPMaterialIndex) in policyMaterials" :key="'mt-'+sinPMaterialIndex+300" class="text-center">
                                                  {{ totalRowItem.materials[sinPMaterial.material_id] ? ($n(totalRowItem.materials[sinPMaterial.material_id], { useGrouping: false }) + ' ' + getMaterialUnitType(sinPMaterial.unit_type)) : '-' }}
                                                </b-th>
                                              </b-tr>
                                            </template>
                                          </b-tfoot>
                                        </b-table-simple>
                                     </div>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col md="12" lg="12">
                                  <div class="text-right mt-2 mb-3 mr-3">
                                    <b-button variant="danger" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { designationWiseUser, farmerDataApi, upazillaDemandApproveDetails, subsidyDemandCorpList } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item', 'officeIdList'],
  components: {
  },
  created () {
    const tmp = this.getformDataData()
    this.getCropInfo(tmp.id)
    this.demmandDetailsData = this.getformDataData()
    this.farAddressData = this.getFarmerInformation(tmp.nid_no)
    this.getForwardDemandList(this.item.circular_id, this.item.upazilla_id)
  },
  data () {
    return {
      demand_details: [],
      demmandDetailsData: {},
      farAddressData: {},
      slOffset: 1,
      crop_names: [],
      loadingState: false,
      policyCrops: [],
      policyMaterials: [],
      approveCheckIds: [],
      rejectCheckIds: [],
      grandLandTotal: 0,
      officerLoading: false,
      officerList: [],
      form: {
        fiscal_year_id: 0,
        circular_id: 0,
        demand_id: 0,
        cropDetails: [],
        materialDetails: [],
        sub_demmand_id: this.id,
        designation_id: 0,
        sender_id: this.$store.state.Auth.authUser.user_id,
        receiver_id: 0,
        comment: ''
      },
      totalRowItem: {
        cash_tk: 0,
        crops: {},
        materials: {}
      }
    }
  },
  watch: {
    'form.designation_id': function (newValue) {
      this.getOfficerList(newValue)
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    },
    designationList: function () {
      return this.$store.state.commonObj.designationList.filter(item => item.status === 0 && item.org_id === 2)
    }
  },
  methods: {
    forwarded (item) {
      this.forwardId = item.id
    },
    getformDataData () {
      return JSON.parse(JSON.stringify(this.item))
    },
    getCropName (cropId) {
      const objectData = this.$store.state.incentiveGrant.commonObj.cropList.find(item => item.value === cropId)
      if (this.$i18n.locale === 'bn') {
        return objectData.text_bn
      } else {
        return objectData.text_en
      }
    },
    async getOfficerList (designationId) {
        this.officerLoading = true
        await RestApi.getData(incentiveGrantServiceBaseUrl, `${designationWiseUser}/${designationId}`)
        .then(response => {
            if (response.success) {
                const data = response.data.data
                this.officerList = data.map(item => {
                    return Object.assign({ value: item.user_id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name })
                })
            } else {
                this.officerList = []
            }
            this.officerLoading = false
        })
    },
    async getFarmerInformation (nid) {
      this.loadingState = true
      const params = Object.assign({}, { nid: nid })
      await RestApi.getData(incentiveGrantServiceBaseUrl, farmerDataApi, params).then(response => {
        if (response.success) {
          this.demmandDetailsData.farmer_data.gender = response.data.gender_id === '1' ? 'male11' : 'Female'
          const divisionObject = this.$store.state.commonObj.divisionList.find(division => division.value === response.data.division_id)
          this.demmandDetailsData.division_id = response.data.division_id
          this.farAddressData.division_name = divisionObject !== undefined ? this.$i18n.locale === 'bn' ? divisionObject.text_bn : divisionObject.text_en : ''
          const districtObject = this.$store.state.commonObj.districtList.find(district => district.value === response.data.district_id)
          this.demmandDetailsData.farmer_data.district_id = response.data.district_id
          this.demmandDetailsData.farmer_data.district_name = districtObject !== undefined ? this.$i18n.locale === 'bn' ? districtObject.text_bn : districtObject.text_en : ''
          const upazillaObject = this.$store.state.commonObj.upazilaList.find(upazillaItem => upazillaItem.value === response.data.upazilla_id)
          this.demmandDetailsData.farmer_data.upazilla = upazillaObject !== undefined ? this.$i18n.locale === 'bn' ? upazillaObject.text_bn : upazillaObject.text_en : ''
          const unionObject = this.$store.state.commonObj.unionList.find(unionItem => unionItem.value === response.data.union_id)
          this.demmandDetailsData.farmer_data.union = unionObject !== undefined ? this.$i18n.locale === 'bn' ? unionObject.text_bn : unionObject.text_en : ''
          const wardObject = this.$store.state.commonObj.wardList.find(wardItem => wardItem.value === response.data.ward_id)
          this.demmandDetailsData.farmer_data.ward_no = wardObject !== undefined ? this.$i18n.locale === 'bn' ? wardObject.text_bn : wardObject.text_en : ''
          const perDistrictObject = this.$store.state.commonObj.districtList.find(perDistrict => perDistrict.value === response.data.per_district_id)
          this.demmandDetailsData.farmer_data.per_district_id = perDistrictObject !== undefined ? this.$i18n.locale === 'bn' ? perDistrictObject.text_bn : perDistrictObject.text_en : ''
          const perUpazillaObject = this.$store.state.commonObj.upazilaList.find(perUpazillaItem => perUpazillaItem.value === response.data.per_upazilla_id)
          this.demmandDetailsData.farmer_data.per_upazilla = perUpazillaObject !== undefined ? this.$i18n.locale === 'bn' ? perUpazillaObject.text_bn : perUpazillaObject.text_en : ''
          const perUnionObject = this.$store.state.commonObj.unionList.find(perUnionItem => perUnionItem.value === response.data.per_union_id)
          this.demmandDetailsData.farmer_data.per_union = perUnionObject !== undefined ? this.$i18n.locale === 'bn' ? perUnionObject.text_bn : perUnionObject.text_en : ''
          const perWardObject = this.$store.state.commonObj.wardList.find(perWardItem => perWardItem.value === response.data.per_ward_id)
          this.demmandDetailsData.farmer_data.per_ward_no = perWardObject !== undefined ? this.$i18n.locale === 'bn' ? perWardObject.text_bn : perWardObject.text_en : ''
        }
      })
      // this.loadingState = false
      return this.demmandDetailsData
    },
    async getForwardDemandList (circularId, upazillaId) {
      this.loadingState = true
      let params = Object.assign({}, { circular_id: circularId, fiscal_year_id: this.item.fiscal_year_id, upazilla_id: upazillaId })
      if (this.$store.state.Auth.authUser.role_id !== 1) {
        const officeId = this.$store.state.Auth.authUser.office_id
        // const officeIdArr = this.getOfficeIdList(officeId)
        params = Object.assign({}, params, {
          office_id: officeId,
          office_id_arr: this.officeIdList
        })
      }
      await RestApi.getData(incentiveGrantServiceBaseUrl, upazillaDemandApproveDetails, params).then(response => {
        if (response.success) {
          this.policyCrops = response.existCropList
          this.policyMaterials = response.existMaterialList
          this.policyCrops.forEach(item => {
            this.totalRowItem.crops[item.crop_id] = 0
          })
          this.policyMaterials.forEach(item => {
            this.totalRowItem.materials[item.material_id] = 0
          })
          this.demand_details = this.getDemandRelatinalData(response.data)
          this.loadingState = false
        } else {
          this.loadingState = false
        }
      })
    },
    getDemandRelatinalData (data) {
      const listData = data.map((item, index) => {
        const union = this.$store.state.commonObj.unionList.find(un => un.value === item.union_id)
        const unionObj = {}
        unionObj.union_name = union !== undefined ? union.text_en : ''
        unionObj.union_name_bn = union !== undefined ? union.text_bn : ''
        const unionTotalRowItem = {
          cash_tk: 0,
          crops: {},
          materials: {}
        }
        this.policyCrops.forEach(item => {
          unionTotalRowItem.crops[item.crop_id] = 0
        })
        this.policyMaterials.forEach(item => {
          unionTotalRowItem.materials[item.material_id] = 0
        })
        item.farmer_info = item.farmer_info.map(farmer => {
          const deReqObj = {
            far_general_info_id: farmer.far_general_info_id,
            subs_demand_id: farmer.id,
            land_quantity: farmer.land_quantity,
            farmer_name: farmer.farmer_data.name,
            farmer_name_bn: farmer.farmer_data.name_bn,
            father_name: farmer.farmer_data.father_name,
            father_name_bn: farmer.farmer_data.father_name_bn,
            nid_no: farmer.farmer_data.nid_no,
            mobile_no: farmer.farmer_data.mobile_no,
            demandCollectioCropList: farmer.demand_collection_details,
            crops: [],
            material: []
          }

          this.grandLandTotal += item.land_quantity ? (parseFloat(item.land_quantity)) : 0
          this.policyCrops.map(tmp => {
            deReqObj.crops.push({ id: tmp.crop_id, quantity: tmp.quantity })
            unionTotalRowItem.crops[tmp.crop_id] += this.getCropQty(tmp, farmer.demand_crop_approval)
            this.totalRowItem.crops[tmp.crop_id] += this.getCropQty(tmp, farmer.demand_crop_approval)
          })

          this.policyMaterials.map(tmpMt => {
            deReqObj.material.push({ id: tmpMt.material_id, quantity: tmpMt.quantity })
            unionTotalRowItem.materials[tmpMt.material_id] += this.getMaterialQty(tmpMt, farmer.demand_material_approval)
            this.totalRowItem.materials[tmpMt.material_id] += this.getMaterialQty(tmpMt, farmer.demand_material_approval)
          })

          unionTotalRowItem.cash_tk += typeof farmer.circular.distribution_policy !== 'undefined' ? parseFloat(farmer.circular.distribution_policy.amount_per_farmer) : 0
          this.totalRowItem.cash_tk += typeof farmer.circular.distribution_policy !== 'undefined' ? parseFloat(farmer.circular.distribution_policy.amount_per_farmer) : 0
          return Object.assign({}, farmer, deReqObj)
        })
        return Object.assign({}, item, unionObj, { union_total: unionTotalRowItem })
      })
      this.form.cropDetails = listData
      return listData
    },
    async getCropInfo (demand) {
      this.loadingState = true
      const params = Object.assign({}, { demand_id: demand })
      await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyDemandCorpList, params).then(response => {
        if (response.success) {
          this.crop_names = response.data.map(item => {
            return item.crop_name
          })
        }
      })
      // this.loadingState = false
      return true
    },
    getCropNameData (singleCrop, detail) {
      const objectData = detail.find(item => item.crop_id === singleCrop.crop_id)
      return objectData !== undefined && objectData.quantity ? this.$n(objectData.quantity, { useGrouping: false }) : '-'
    },
    getCropQty (singleCrop, detail) {
      const objectData = detail.find(item => item.crop_id === singleCrop.crop_id)
      return objectData !== undefined && objectData.quantity ? parseFloat(objectData.quantity) : 0
    },
    getMeterialNameData (singleMetrial, details) {
      const objectData = details.find(item => item.material_id === singleMetrial.material_id)
      return objectData !== undefined && objectData.quantity ? this.$n(objectData.quantity, { useGrouping: false }) : '-'
    },
    getMaterialQty (singleMetrial, details) {
      const objectData = details.length ? details.find(item => item.material_id === singleMetrial.material_id) : undefined
      return objectData !== undefined && objectData.quantity ? parseFloat(objectData.quantity) : 0
    },
    getCropUnitType (unit) {
      if (unit === 1) {
        return this.$t('dae_grant_allocation_distribution.kg')
      }
    },
    getMaterialUnitType (unit) {
      if (unit === 1) {
        return this.$t('dae_grant_allocation_distribution.kg')
      }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>

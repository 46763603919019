<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dae_grant_allocation_distribution.vill_sub_demand_approve') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.fiscal_year')"
                    label-for="fiscal_year_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.fiscal_year_id"
                        :options="fiscalList"
                        id="fiscal_year_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('dae_config.season_name')"
                    label-for="season_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.season_id"
                        :options="seasonList"
                        id="season_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('dae_grant_allocation_distribution.circular')"
                    label-for="circular_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.circular_id"
                        :options="circularList"
                        id="circular_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('dae_grant_allocation_distribution.project')"
                    label-for="project_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.project_id"
                        :options="projectNameList"
                        id="project_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="3"
                  :label="$t('org_pro_division.division')"
                  label-for="division_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.division_id"
                      :options="divisionList"
                      id="division_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="3"
                  :label="$t('org_pro_district.district')"
                  label-for="district_id"
                  >
                    <b-form-select
                      plain
                      v-model="search.district_id"
                      :options="districtList"
                      id="district_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('org_pro_upazilla.upazilla')"
                  label-for="upazilla_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.upazilla_id"
                      :options="upazilaList"
                      id="upazilla_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('dae_grant_allocation_distribution.vill_sub_demand_approve_list') }}</h4>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loadingState">
                          <b-row>
                              <b-col md="12" class="table-responsive">
                                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                      <template v-slot:cell(index)="data">
                                          {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(mobile_no)="data">
                                          {{ $n(data.item.mobile_no, { useGrouping: false }) }}
                                      </template>
                                      <template v-slot:cell(nid_no)="data">
                                          {{ $n(data.item.nid_no, { useGrouping: false }) }}
                                      </template>
                                      <template v-slot:cell(total_land)="data">
                                          {{ $n(data.item.total_land) }}
                                      </template>
                                      <template v-slot:cell(total_farmer)="data">
                                          {{ $n(data.item.total_farmer) }}
                                      </template>
                                      <template v-slot:cell(status)="data">
                                        {{ getStatus(data.item.status) }}
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                      </template>
                                  </b-table>
                                  <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                      />
                              </b-col>
                          </b-row>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="xl" :title="$t('dae_grant_allocation_distribution.vill_sub_demand_approve_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="dtlsItemId" :officeIdList="officeIdList"></Details>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyDemandCollectionApprovedList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        season_id: 0,
        circular_id: 0,
        project_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        receiver_id: this.$store.state.Auth.authUser.user_id
      },
      districtList: [],
      upazilaList: [],
      rows: [],
      item: '',
      itemId: 0,
      subsidyNameList: [],
      officeIdList: [],
      dtlsItemId: ''
    }
  },
  mounted () {
    if (this.$store.state.Auth.authUser.role_id !== 1) {
      const officeId = this.$store.state.Auth.authUser.office_id
      this.officeIdList = []
      this.getOfficeIdList(officeId)
      const officeIdArr = this.getOfficeIdList(officeId)
      this.search = Object.assign({}, this.search, {
        office_id: officeId,
        office_id_arr: officeIdArr
      })
    }
    this.loadData()
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    circularList: function () {
      const circularData = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1)
      return circularData.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    cropList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en }
        }
      })
    },
    seasonList: function () {
      return this.$store.state.incentiveGrant.commonObj.seasonList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    fiscalList: function () {
      const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyearData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    subsidyTypeList: function () {
      const subsidyTypeData = this.$store.state.incentiveGrant.commonObj.subsidyTypeList.filter(item => item.status === 1)
      return subsidyTypeData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    projectNameList: function () {
      const projectNameData = this.$store.state.incentiveGrant.commonObj.projectList.filter(item => item.status === 1)
      return projectNameData.map(item => {
          if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
          } else {
          return { value: item.value, text: item.text_en }
          }
      })
    },
    cropNameList: function () {
      const cropNameData = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
      return cropNameData.map(item => {
          if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
          } else {
          return { value: item.value, text: item.text_en }
          }
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('dae_grant_allocation_distribution.vill_sub_demand_collection') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_grant_allocation_distribution.vill_sub_demand_collection') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('dae_grant_allocation_distribution.fiscal_year'), class: 'text-center' },
          { label: this.$t('dae_grant_allocation_distribution.season'), class: 'text-center' },
          { label: this.$t('dae_config.circular_type'), class: 'text-center' },
          { label: this.$t('dae_grant_allocation_distribution.circular'), class: 'text-center' },
          { label: this.$t('dae_grant_allocation_distribution.project'), class: 'text-center' },
          { label: this.$t('globalTrans.upazila'), class: 'text-center' },
          { label: this.$t('dae_grant_allocation_distribution.total_farmer'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'season_bn' },
          { key: 'circular_type_bn' },
          { key: 'circular_bn' },
          { key: 'project_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'total_farmer' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'seasson' },
          { key: 'circular_type' },
          { key: 'circular' },
          { key: 'project' },
          { key: 'upazilla_name' },
          { key: 'total_farmer' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.subsidy_type_id': function (newValue) {
      this.subsidyNameList = this.getSubsidyNameBySubsidyType(newValue)
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    demandForward (item) {
      this.itemId = item.id
    },
    details (item) {
      this.item = item
      this.dtlsItemId = item.id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyDemandCollectionApprovedList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList
      const projectNameList = this.$store.state.incentiveGrant.commonObj.projectList
      const circularTypeList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
      const circularList = this.$store.state.incentiveGrant.commonObj.circularList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazillaList = this.$store.state.commonObj.upazilaList
      const listData = data.map(item => {
        const fiscalObject = fiscalYearList.find(fiscal => fiscal.value === item.fiscal_year_id)
        const seasonObject = this.$store.state.incentiveGrant.commonObj.seasonList.find(season => season.value === item.season_id)
        const projectNameObject = projectNameList.find(projectName => projectName.value === item.project_id)
        const circularObject = circularList.find(circularSingle => circularSingle.value === item.circular_id)
        const cirTypeObject = circularTypeList.find(ct => ct.value === circularObject.circular_type)
        const divisionObject = divisionList.find(upz => upz.value === item.division_id)
        const districtObject = districtList.find(upz => upz.value === item.district_id)
        const upazillaObject = upazillaList.find(upz => upz.value === item.upazilla_id)
        const farmerObject = item.farmer_data
        const fiscalyearData = {
          fiscal_year: fiscalObject !== undefined ? fiscalObject.text_en : '',
          fiscal_year_bn: fiscalObject !== undefined ? fiscalObject.text_bn : ''
        }
        const seasonData = {
          seasson: seasonObject !== undefined ? seasonObject.text_en : '',
          season_bn: seasonObject !== undefined ? seasonObject.text_bn : ''
        }
        const projectNameData = {
          project: projectNameObject !== undefined ? projectNameObject.text_en : '',
          project_bn: projectNameObject !== undefined ? projectNameObject.text_bn : ''
        }
        const cirTypeData = {
          circular_type: cirTypeObject !== undefined ? cirTypeObject.text_en : '',
          circular_type_bn: cirTypeObject !== undefined ? cirTypeObject.text_bn : ''
        }
        const circularNameData = {
          circular: circularObject !== undefined ? circularObject.text_en : '',
          circular_bn: circularObject !== undefined ? circularObject.text_bn : ''
        }
        const divisionData = {
          division_name: divisionObject !== undefined ? divisionObject.text_en : '',
          division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
        }
        const districtData = {
          district_name: districtObject !== undefined ? districtObject.text_en : '',
          district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
        }
        const upazillaData = {
          upazilla_name: upazillaObject !== undefined ? upazillaObject.text_en : '',
          upazilla_name_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ''
        }
        const farmerData = {
          father_name: farmerObject !== undefined ? farmerObject.father_name : '',
          father_name_bn: farmerObject !== undefined ? farmerObject.father_name_bn : '',
          mobile_no: farmerObject !== undefined ? farmerObject.mobile_no : '',
          nid_no: farmerObject !== undefined ? farmerObject.nid_no : ''
        }
        return Object.assign({}, item, fiscalyearData, seasonData, cirTypeData, circularNameData, projectNameData, divisionData, districtData, upazillaData, farmerData)
      })
      return listData
    },
    getSubsidyNameBySubsidyType (typeId) {
      const dataObject = this.$store.state.incentiveGrant.commonObj.subsidyList.filter(item => item.status === 1 && item.subsidy_type_id === typeId)
      return dataObject.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('budget.forward')
      } else if (status === 3) {
        return this.$t('budget.approve')
      }
    },
    getDistrictList (DivisionId) {
      const list = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && item.division_id === DivisionId)
      return list.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getOfficeIdList (officeId) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === officeId)
      if (typeof office !== 'undefined') {
        this.officeIdList.push(officeId)
      }
      const childOfficeList = this.$store.state.commonObj.officeList.filter(item => item.parent_office_id === officeId)
      childOfficeList.forEach(childOffice => {
        this.getOfficeIdList(childOffice.value)
      })
      return this.officeIdList.filter((value, index, self) => {
        return self.indexOf(value) === index
      })
    }
  }
}
</script>
